.button{
    transition: 0.2s ease;
    outline: none !important;
    padding: 0 14px;
    padding-top: 2px;
    min-height: 68px;
    border-radius: 2px;
    border: none;
    display: flex;
    align-items: center;
    font-family: "source-sans-pro", sans-serif;
    *{font-family: "source-sans-pro", sans-serif;}
    font-weight: 500;
    text-transform: uppercase;
    justify-content: center;
    color: #fff !important;
    letter-spacing: 2px;
    font-size: 24px;
    box-shadow: 2px 2px 4px 0px rgb(64 67 93 / 35%);
    text-decoration: none !important;
    &:hover{
        filter: brightness(1.1);
    }
    &.default{
        background: $secondary-color;
        &:hover{
            filter: brightness(1.1);
        }
        &:active{
            filter: brightness(1) !important;
            background-color: darken($secondary-color, 5%);
        }
    }
    &.light{
        background: transparent;
        box-shadow: none;
        border: 2px solid $secondary-color;
        color: $secondary-color !important;
        &:hover{
            filter: brightness(1) !important;
            color: #fff !important;
            background-color: $secondary-color;
        }
        &:active{
            background-color: darken($secondary-color, 5%);
        }
    }
    &:disabled,
    &.disabled{
        box-shadow: 0px 0px 0px 0px rgb(64 67 93 / 35%);
        pointer-events: none;
        opacity: .7;
        &.default{
            opacity: 1;
            background-color: #e7878b;
        }
    }
}