@import "./assets/scss/reset.scss";
@import 'bootstrap/scss/bootstrap.scss';
@import 'ngx-toastr/toastr';
@import "./assets/scss/variables.scss";
@import "./assets/scss/animations.scss";
@import "./assets/scss/ie.scss";
@import "./assets/scss/icons.scss";
@import "./assets/scss/buttons.scss";
@import "./assets/scss/modals.scss";
@import "./assets/scss/fields.scss";
@import "./assets/scss/fonts.scss";
@import "./assets/scss/im-webfont.scss";
@import "./assets/scss/tables.scss";
@import "./assets/scss/content.scss";
@import "./assets/scss/toasts.scss";


// general
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $placeholder-color;
}
::-moz-placeholder { /* Firefox 19+ */
    color: $placeholder-color;
}
:-ms-input-placeholder { /* IE 10+ */
    color: $placeholder-color;
}
:-moz-placeholder { /* Firefox 18- */
    color: $placeholder-color;
}
::placeholder {
    color: $placeholder-color;
}
body * {
    font-family: "source-sans-pro",sans-serif;
    color: $text-color;
}
body{
    background-color: $off-white;
    min-height: 100vh;
}
.container {
    max-width: 767px !important;
    max-width: 800px !important;
    background-color: #fff;
    padding: 0;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
}
main{
    // max-height: 1024px;
    max-height: 1050px;
    height: calc(100vh);
    min-height: 850px;
}
.page{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

// helpers
.saving--pre{
    transition: .15s ease;
}
.saving{
    opacity: .7;
    pointer-events: none;
}
.opacity-hide{
    transition: .2s ease;
    opacity: 0;
    pointer-events: none;
}
.opacity-show{
    opacity: 1 !important;
    pointer-events: all !important;
}
.nowrap{
    white-space: nowrap;
}
.no-transition{
    transition: none !important;
    transform: none !important;
}

// speech bubble
.speech-bubble{
    background-color: $light-gray;
    // min-height: 870px;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding: 24px;
    overflow: hidden;
    position: relative;
    min-height: 380px;
    // overflow-y: auto;
}

// general
h1{
    font-family: 'centra-font', sans-serif !important;
    * {font-family: 'centra-font', sans-serif !important;}
    color: $primary-color;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
}
h2{
    font-family: 'centra-font', sans-serif !important;
    * {font-family: 'centra-font', sans-serif !important;}
    color: $primary-color;
    font-size: 24px;
    // font-weight: 600;
    font-weight: 700;
    line-height: 36px;
    border-bottom: 4px solid $secondary-color;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    // letter-spacing: 1px;
}
.title-sep{
    background-color: $primary-color;
    width: 134px;
    height: 5px;
}
.descr{
    color: $text-color;
    font-size: 26px;
    line-height: 30px;
    font-family: 'centra-font', sans-serif !important;
    &:hover{
        color: $text-color;
    }
}

@media only screen and (max-width: 600px) {
    body{
        background-color: #fff;
    }
    main{
        min-height: 0px;
        height: auto;
    }
    .container {
        padding-left: 16px;
        padding-right: 16px;
    }
    .speech-bubble{
        padding: 16px;
        padding-bottom: 40px;
    }
    h1{
        font-size: 32px;
        line-height: 36px;
    }
    .descr{
        font-size: 22px;
        line-height: 26px;
    }
}