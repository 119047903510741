@import "./variables.scss";
.field {
    .label{
        // color: $primary-color;
        // font-size: 15px;
        // line-height: 18px;
        // display: block;
        // margin-bottom: 8px;
        // margin-bottom: 7px;
        // font-weight: 500;
        // white-space: nowrap;
        // span{
        //     font-weight: 300;
        //     margin-left: 3px;
        //     font-size: 12px;
        // }
        margin-bottom: 2px;
        display: block;
    }
    .error-field{
        border: 1px solid $error-color !important;
    }
    .error-field-display {
        display: block;
        font-size: 20px;
        line-height: 24px;
        color: $error-color;
        padding-top: 5px;
        text-align: center;
    }
    &.field-select{
        .options-inner{
            overflow-y: auto;
            max-height: 452px;
        }
        .options, .dropdown-menu{
            position: absolute;
            top: 100%;
            background-color: transparent;
            box-shadow: 0px 2px 8px 0px rgba(53, 84, 119, 0.3);
            z-index: 9999;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            .option, .dropdown-item{
                &:last-of-type{
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
                font-family: 'Roboto', sans-serif;
                *{font-family: 'Roboto', sans-serif;}
            }
        }
        // .dropdown-menu{
        //     padding: 0;
        //     margin: 0;
        //     border: none;
        //     border-radius: 0;
        //     transform: none !important;
        //     border-bottom-left-radius: 8px;
        //     border-bottom-right-radius: 8px;
        //     width: 100%;
        //     left: 0;
        //     top: 0;
        //     border-radius: 8px;
        //     overflow: hidden;
        //     padding-top: 48px;
        //     max-height: 500px;
        //     overflow-y: auto;
        //     font-size: $fontsize-field;
        //     *{font-size: $fontsize-field;}
        //     padding-top: 0;
        //     top: 100% !important;
        //     border-top-left-radius: 0;
        //     border-top-right-radius: 0;
        //     .dropdown-item{
        //         background-color: #fff;
        //         font-size: $fontsize-field;
        //         color: $text-color;
        //         min-height: 48px;
        //         padding: 0 16px;
        //         white-space: nowrap;
        //         border-left: 1px solid $border-color;
        //         border-right: 1px solid $border-color;
        //         cursor: pointer;
        //         border-top: 1px solid $border-color;
        //         &:hover *{
        //             color: $primary-color !important;
        //         }
        //         &:first-of-type{
        //             border-top: none;
        //         }
        //         &:last-of-type{
        //             border-bottom: 1px solid $border-color;
        //         }
        //         ngb-highlight{
        //             white-space: nowrap;
        //             overflow: hidden;
        //             text-overflow: ellipsis;
        //             display: block;
        //             .ngb-highlight{
        //                 font-weight: 400 !important;
        //                 text-decoration: underline !important;
        //             }
        //         }
        //     }
        // }
        .dropdown-menu{
            padding: 0;
            margin: 0;
            border: none;
            border-radius: 0;
            transform: none !important;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            width: 100%;
            left: 0;
            top: 0;
            border-radius: 4px;
            overflow: hidden;
            padding-top: 48px;
            max-height: 420px;
            // max-height: 370px;
            overflow-y: auto;
            font-size: 14px;
            *{font-size: 14px;}
            padding-top: 0;
            top: 100% !important;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            .dropdown-item{
                background-color: #fff;
                color: $text-color;
                min-height: 40px;
                padding: 0 8px;
                white-space: nowrap;
                border-left: 1px solid #E5E5E5;
                border-right: 1px solid #E5E5E5;
                cursor: pointer;
                border-top: 1px solid #E5E5E5;
                &:hover{
                    color: white;
                    background-color: $primary-color;
                    *{color: white;}
                }
                &.active{
                    color: white;
                    background-color: $primary-color;
                    *{color: white;}
                }
                &:first-of-type{
                    border-top: none;
                }
                &:last-of-type{
                    border-bottom: 1px solid #E5E5E5;
                }
                ngb-highlight{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                    .ngb-highlight{
                        font-weight: 400 !important;
                        text-decoration: underline !important;
                    }
                }
            }
        }
    }
}

textarea{
    display: block;
    width: 100%;
    border: 1px solid $border-color;
    border-radius: 2px;
    outline: none;
    background-color: $off-white;
    font-size: $fontsize-field;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    box-shadow: inset 2px 2px 3px rgb(0 0 0 / 11%);
    text-align: left;
    padding: 8px 12px;
    max-width: 400px;
    width: 100%;
}

// ng bootstrap datepicker

ngb-datepicker{
    box-shadow: 0 3px 12px rgb(144 144 144 / 25%) !important;
    border: none !important;
}
.ngb-dp-navigation-select{
    .form-select{
        &:first-of-type{
                border: 0 !important;
                background: none !important;
                outline: none !important;
                text-align: right !important;
                font-weight: 500 !important;
                color: #000 !important;
                box-shadow: none !important;
                padding-right: 2px !important;
        }
        &:last-of-type{
                border: 0 !important;
                background: none !important;
                outline: none !important;
                text-align: left !important;
                font-weight: 500 !important;
                box-shadow: none !important;
                color: #000 !important;
                padding-left: 2px !important;
        }
    }
}
.ngb-dp-arrow{
    &:first-of-type{
        .ngb-dp-navigation-chevron{
            width: 7px !important;
            height: 7px !important;
            border-width: 1px 1px 0 0 !important;
            display: block !important;
        }
    }
    &:last-of-type{
        .ngb-dp-navigation-chevron{
            width: 7px !important;
            height: 7px !important;
            border-width: 1px 1px 0 0 !important;
            display: block !important;
        }
    }
}
.ngb-dp-header{
    background-color: transparent !important;
}
.ngb-dp-weekdays{
    background-color: transparent !important;
    border-bottom: none !important;
}
.bg-primary{
    background-color: $primary-color !important;
}
.ngb-dp-weekday{
    color: #000 !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
}