// colors
$primary-color: #40435D;
$secondary-color: #E94E55;
$light-gray: #DFE1E3;
$off-white: #F9F9F9;
$error-color: #E94E55;
$text-color: #40435D;
$border-color: #40435D; 
$placeholder-color: rgb(207, 207, 207);

.primary-color{
    color: $primary-color;
}
.secondary-color{
    color: $secondary-color;
}

.primary-background{
    background-color: $primary-color;
}
.secondary-background{
    background-color: $secondary-color;
}

// var
$fontsize-field: 22px;