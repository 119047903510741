@font-face {
    font-family: 'centra-font';
    src: url('../font/centra/centra-light/@font-face/23ae4288a9ec35109fa15c3d90bcc359.ttf') format('truetype');
    font-weight: 300;
}
@font-face {
    font-family: 'centra-font';
    src: url('../font/centra/centra/@font-face/229a57bbeef591e04a1fdfe347c90864.ttf') format('truetype');
    font-weight: 400;
}
@font-face {
    font-family: 'centra-font';
    src: url('../font/centra/centra-bold/@font-face/1d8aa3902ed39929de0912f0ff2bb89f.ttf') format('truetype');
    font-weight: 500;
}
@font-face {
    font-family: 'centra-font';
    src: url('../font/centra/centra-black/@font-face/82154d888c0413159b25be2b1d1eb81d.ttf') format('truetype');
    font-weight: 600;
}
@font-face {
    font-family: 'centra-font';
    src: url('../font/centra/centra-extra-bold.ttf') format('truetype');
    font-weight: 700;
}



// REGULAR

// font-family: source-sans-pro, sans-serif;
// font-weight: 400;
// font-style: normal;

// font-family: source-sans-pro, sans-serif;
// font-weight: 400;
// font-style: italic;


// BOLD

// font-family: source-sans-pro, sans-serif;
// font-weight: 700;
// font-style: normal;

// font-family: source-sans-pro, sans-serif;
// font-weight: 700;
// font-style: italic;


// BLACK

// font-family: source-sans-pro, sans-serif;
// font-weight: 900;
// font-style: normal;

// font-family: source-sans-pro, sans-serif;
// font-weight: 900;
// font-style: italic;
