@import "./variables.scss";
// body::after{
//     position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
//     content: 
//         url(/assets/img/nav/home-active.svg)
//         url(/assets/img/nav/contact-active.svg)
//         url(/assets/img/nav/info-active.svg)
//         url(/assets/img/icons/nav-chev-down-active.svg)
//         url(/assets/img/icons/download-inline-active.svg)
//         url(/assets/img/icons/order.svg)
//         url(/assets/img/icons/order-asc.svg)
//         url(/assets/img/icons/order-desc.svg)
// }
.icon{
    flex-shrink: 0;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    &.icon-home{
        background-image: url(/assets/img/icons/home.svg);
        width: 18px;
        height: 18px;
    }
}