.modal{
    i.icon-cancel{
        cursor: pointer;
    }
    &.main-modal .modal-dialog{
        max-width: 600px;
    }
    &.detail-modal .modal-dialog{
        width: 1406px;
        max-width: calc(100% - 40px);
        .modal-content{
            min-height: 700px !important;
            padding: 0;
        }
    }
    .modal-dialog{
        // height: auto !important;
        overflow: auto !important;
        border-radius: 8px !important;
        margin-top: 144px;
        margin-bottom: 80px;
        .modal-content{
            border: none;
            box-shadow: 0px 2px 8px 0px rgba(53, 84, 119, 0.2);
            overflow: hidden;
        }
    }
    &.modal-md{
        .modal-dialog{
            margin-top: 300px;
            width: 720px;
            max-width: calc(100% - 20px);
        }
    }
}

.modal-content{
	animation-name: modalAnimation;
	animation-duration: .1s;
}

@keyframes modalAnimation {
	0%   {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.modal-backdrop{
	animation-name: modalAnimationBackdrop;
    animation-duration: .1s;
    background-color: #0A0A0A;
    &.modal-backdrop--route{
        z-index: 1040 !important;
    }
}
.modal--route{
    z-index: 1041 !important;
}

@keyframes modalAnimationBackdrop {
	0%   {
		opacity: 0;
	}
	100% {
		opacity: .5;
	}
}

@media only screen and (max-height: 1000px) {
    .modal{
        &.modal-md{
            .modal-dialog{
                margin-top: 250px;
            }
        }
    }
    
}

@media only screen and (max-height: 900px) {
    .modal{
        &.modal-md{
            .modal-dialog{
                margin-top: 200px;
            }
        }
    }
    
}

@media only screen and (max-width: 720px) {
    .modal{
        .modal-dialog{
            margin: 16px 16px 16px 16px !important;
            max-width: calc(100% - 32px) !important;
            .modal-content{
                border: none;
                box-shadow: 0px 2px 8px 0px rgba(53, 84, 119, 0.2);
                overflow: hidden;
            }
        }
        &.modal-md{
            .modal-dialog{
                margin: 32px 32px 32px 32px !important;
                max-width: calc(100% - 64px) !important;
            }
        }
    }
}