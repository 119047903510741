@import "./variables.scss";

#toast-container{
    max-width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    justify-content: center;
    * {font-family: 'centra-font', sans-serif !important;}
    .ngx-toastr{
        max-width: 100%;
        box-shadow: 0 3px 12px 0 rgba(144, 144, 144, 0.3) !important;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 62px;
        padding-right: 28px;
        border-radius: 4px;
        background: #ffffff;
        margin: 0 0 11px;
        width: 400px;

        opacity: 1;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        display: inline-block;
        margin-bottom: 32px;
        .toast-close-button{
            background-image: url(/assets/img/icons/x.svg);
            background-repeat: no-repeat;
            background-size: contain;
            width: 10px;
            height: 10px;
            transition: .1s ease;
            position: absolute;
            top: 50%;
            right: 13px;
            transform: translatey(-50%);
            &:hover{
                opacity: .7;
            }
            span{
                display: none;
            }
        }
    }
    .toast-success{
        border-left: 5px solid var(--green);
    }
    .toast-warning{
        border-left: 5px solid var(--orange);
    }
    .toast-error{
        border-left: 5px solid var(--red);
    }
    .toast-info{
        border-left: 5px solid var(--secondary);
    }
    .toast-bottom-right {
        right: 16px;
        bottom: 0px;
    }
    .toast-title{
        font-size: 20px;
        color: var(--text-color);
    }
    .toast-message{
        font-size: 18px;
        color: #555555;
    }
    .toast-warning{
        background-image: url(/assets/img/icons/icon-toast-warning.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-error{
        background-image: url(/assets/img/icons/icon-toast-error.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-info{
        background-image: url(/assets/img/icons/icon-toast-info.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-success{
        background-image: url(/assets/img/icons/icon-toast-success.svg);
        background-repeat: no-repeat;
        background-size: 21px 21px;
        background-position: center left 15px;
    }
    
}